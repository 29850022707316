import React from "react";
import { Link } from "gatsby";

// import defaultImg from "src/images/news/default-cover-img-3.jpg";

/**
 *  A category section within the global nav
 */
class NavCategorySection extends React.Component {

  state = {
    featuredArticle: []
  }

  componentDidMount() {
    fetch(`https://thehelm.polb.com//wp-json/wp/v2/featured-articles?slug=${this.props.slug}`)
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          this.setState({ featuredArticle: json });
        }
        else {
          console.log("NO more " + this.props.slug);
        }
      })
  }

  render() {
    const props = this.props;
    const feature = this.state.featuredArticle[0];
    
    // console.log(props);

    return (

      <div id={props.catSectionId} className="nav__section">
        <ul className="nav__cat-subs">
          {
            props.links.map((link) => {
             
              let correctPath;
              let fullPath = link.url;
              if (fullPath === "https://thehelm.polb.com/documents/ceqa-nepa/") {
                let cutPath = fullPath.substring(24);
                let relativePath = `${cutPath.substring(0, 10)}#${cutPath.substring(11)}`;
                correctPath = relativePath;
              } else {
                correctPath = fullPath;
              }
              return (

                    correctPath.substring(0, 4) === "http" ?
                      <li key={link.object_id } className="nav__cat-sub">
                        <a href={correctPath} target="_blank" className="nav__cat-sublink">{link.title.replace('&#038;', '&')}</a>
                      </li>
                      :
                      <li key={link.object_id } className="nav__cat-sub">
                        <Link to={correctPath} className="nav__cat-sublink">{link.title.replace('&#038;', '&')}</Link>
                      </li>
                  
              
              )
            })
          }
        </ul>

        {this.state.featuredArticle.length > 0 ?

          <div className="nav__feature">
            {
              feature.acf.image && <img src={feature.acf.image.sizes.medium} alt="" className="nav__feature-img" />
            }

            <h3 id={`${props.catSectionId}-feature`} className="nav__feature-title">{feature.acf.title}</h3>
            <p className="nav__feature-excerpt">{feature.acf.excerpt}</p>
            <a href={feature.acf.url.url} className="nav__feature-link view-more" target="_blank" rel={feature.acf.url.target} >{feature.acf.url.title}</a>

          </div>
          :
          null
        }

      </div>

    );
  }
}

export default NavCategorySection;
