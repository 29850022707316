import React from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";

import LiveRegion from "src/components/live-region";
import Nav from "src/components/nav";
import logo from "src/images/logo-polb-update-final-2.png";    // for narrow viewports (mobile header)
import logo2 from "src/images/logo-polb-update-final-alternate-2.png"; // for wide viewports when nav open
import loading from "../images/loading.gif";

const fetch = require('isomorphic-fetch')

/**
 *  Page header
 */
class Header extends React.Component {
  constructor(props) {
    super(props);

    this.searchInput = React.createRef();
    this.handleDataCall = this.handleDataCall.bind(this);
    this.handleUpdateState = this.handleUpdateState.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.closeNavAfterMouseOut = this.closeNavAfterMouseOut.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  state = {
    largeNavClosed: true,
    searchButtonClick: false,
    searchResults: [],
    searchValue: "",
    windowWidthMinimum: false,
    marginHeight: 0
  };

  componentDidMount() {
    this.updateDimensions();
    if (typeof window !== 'undefined') {
      window.addEventListener("resize", this.updateDimensions);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    if (window.innerWidth > 1279) {
      this.setState({ windowWidthMinimum: true });
    } else {
      this.setState({ windowWidthMinimum: false });
    }
  }

  handleSearchClick() {
    this.setState({ largeNavClosed: true });
    this.setState({ searchButtonClick: !this.state.searchButtonClick }, () => {
      if (window.document.getElementById("search-site")) {
        window.document.getElementById("search-site").focus();
      }
    });
  }

  // Switch header style when non-mobile version of nav open. Is called from <Nav />.
  handleUpdateState(navState) {
    const anyNavSectionOpen = navState.some(section => section === true);

    if (anyNavSectionOpen) {
      this.setState({
        largeNavClosed: false
      });
    } else {
      this.setState({
        largeNavClosed: true
      });
    }
  }

  handleDataCall(e) {
    e.preventDefault();
    const value = this.searchInput.current.value;

    if (window.document.getElementById("header-loading")) {
      window.document.getElementById("header-loading").classList.add("show-loading");
    }

    fetch(`https://thehelm.polb.com/wp-json/relevanssi/v1/search?s=${value}`)
      .then(response => response.json())
      .then(json => {
        this.setState({
          searchResults: json.results,
          searchButtonClick: false,
          searchValue: value
        });
        navigate("/documents/search-results", { state: { searchResults: this.state.searchResults, searchValue: this.state.searchValue } });
      })
  }

  closeNavAfterMouseOut() {
    if (this.state.largeNavClosed === false) {
      this.setState({ largeNavClosed: true })
    }
  }

  render() {
    // const trueMargin = this.state.marginHeight === 0 ? this.state.marginHeight : this.state.marginHeight + 18;
    // const trueMargin = this.state.marginHeight;

    // Generate class names for header
    const createClassNames = () => {
      let classes = `${this.props.omitBackground ? 'header' : 'header header--bg'}`;

      if (!this.state.largeNavClosed) {
        classes += ' header--large-nav-open';
      }

      if (this.state.searchButtonClick) {
        classes += ' white--background';
      }

      return classes;
    }

    return (
      <header className={createClassNames()} aria-label="site header" onMouseLeave={this.state.windowWidthMinimum ? this.closeNavAfterMouseOut : null} >
        <div className={`header__inner ${this.state.searchButtonClick ? 'hide-logo-image' : ''}`}>
          <Link to="/" className="header__logo-link header__polb-logo">
            <img src={logo} alt="Port of Long Beach logo" className="header__logo" />
            <img src={logo2} alt="Port of Long Beach logo" className="header__logo-2" />
          </Link>
          {this.state.searchButtonClick ?

            (
              <div className="search--div">
                <form className="global-search-form form-search-subpage search--div--top--search" onSubmit={this.handleDataCall}>
                  <button type="submit" className="global-search form-search-subpage__btn button--hover--cursor" aria-label="submit search form"><svg className="icon--search--results"><use xlinkHref="#icon-search" /></svg></button>
                  <label htmlFor="search-site" className="visuallyhidden">Search the site:</label>
                  <input type="search" id="search-site" placeholder="Search" className="global-search-bar form-search-subpage__field" ref={this.searchInput} />
                  <div id="header-loading" className="loading-container"><img src={loading} alt="loading..." /></div>
                </form>
                <button type="button" className="global-search-close form-search-subpage__btn button--hover--cursor" onClick={this.handleSearchClick} aria-label="close search form"><svg className="icon--close--search"><use xlinkHref="#icon-close" /></svg></button>
              </div>
            )

            :

            (
              <React.Fragment>
                <Nav updateHeaderState={this.handleUpdateState} closeCategory={this.state.largeNavClosed} />
                <button type="button" className="global-search__btn button--hover--cursor" onClick={this.handleSearchClick} aria-label="toggle search form"><svg className="icon--search--results"><use xlinkHref="#icon-search" /></svg></button>
                <Link to="/documents/" className="header__admin-link" aria-label="documents"><svg className="icon icon--docs"><use xlinkHref="#icon-docs" /></svg></Link>
                <Link to="/port-info/map/" className="header__admin-link" aria-label="map"><svg className="icon icon--worldwide"><use xlinkHref="#icon-worldwide" /></svg></Link>
              </React.Fragment>
            )

          }

          {/* For accessibility improvement. Announce which page has loaded. */}
          <LiveRegion regionId="page-region" pageTitle={this.props.pageTitle} message="page loaded" />
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  omitBackground: PropTypes.bool.isRequired,
};

export default Header;
